import React from "react";
import {useNavigate} from "react-router-dom";
import {Fab} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

export default function ReturnToHomeBtn() {
    const navigate = useNavigate()
    const returnAtHome = () => {
        navigate("/")
    }
    return  <Fab size="large" color={"secondary"} aria-label="return to home" onClick={returnAtHome}>
        <HomeIcon/>
    </Fab>
}