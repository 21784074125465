import React from "react";
import {Autocomplete, TextField} from "@mui/material";
import {ErrorMessage} from "formik";
import {getLocaleForFields, getOptionLabel} from "../../help/lang";
import {useDefaultContext} from "../../context/WSContext";

export const GrapesField = ({props, isSendAnswer}) => {
    const locale = getLocaleForFields();
    const {grapesDictionary, t} = useDefaultContext();

    return <Autocomplete id={"grapes"}
                         name={"grapes"}
                         value={props.values?.grapes}
                         onChange={(_, value) => props.setFieldValue("grapes", value)}
                         onBlur={props.handleBlur}
                         error={props.errors.grapes && props.touched.grapes}
                         variant="outlined"
                         fullWidth
                         multiple
                         limitTags={2}
                         options={grapesDictionary || []}
                         getOptionLabel={(option) => getOptionLabel(locale, option, t)}
                         defaultValue={[]}
                         disabled={isSendAnswer || false}
                         isOptionEqualToValue={(ob1, ob2) => {
                             return ob1?.code === ob2?.code
                         }}
                         required={true}
                         renderInput={(params) => (
                             <TextField {...params} label={t("Виноград")}
                                        helperText={<ErrorMessage name={"grapes"}/>}
                                        placeholder=""
                                        required={props.values?.grapes?.length === 0}/>
                         )}
    />
}