import React from 'react';
import {deleteWine} from "../../requests/api";
import {IconButton, Stack, Tooltip} from "@mui/material";
import {useDefaultContext, useWSContext} from "../../context/WSContext";
import {PaperItem} from "../base";
import {WineDescriptionCard} from "../wineViewUtils";
import DeleteIcon from '@mui/icons-material/Delete';

export const WineCard = ({wine}) => {

    const {uuid} = useWSContext();
    const {currentUser, t} = useDefaultContext();

    const isOwner = currentUser?.uuid === wine?.owner?.uuid;
    const canDelete = isOwner && wine?.state === "CREATED";

    const handleDelete = () => {
        if (canDelete) {
            deleteWine(uuid, wine.uuid).then()
        }
    };

    return <PaperItem key={wine.uuid} elevation={6}>
        <Stack direction={"column"} sx={{p: 1, height: '100%'}} gap={1}>
            <WineDescriptionCard wine={wine}>
                {
                    canDelete && <Tooltip title={t("Удалить вино может только его владелец")}>
                    <span>
                        <IconButton size="medium" disabled={!canDelete} onClick={handleDelete}>
                        <DeleteIcon fontSize="inherit" color={canDelete ? "error" : "disabled"}/></IconButton>
                    </span>
                    </Tooltip>
                }
            </WineDescriptionCard>
        </Stack>
    </PaperItem>
}
