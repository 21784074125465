import {Client} from '@stomp/stompjs';

export let stompClient = null;

const baseURL= process.env.REACT_APP_WS_BASE_URL || "";

export function connect(degustationUUID, func) {
    stompClient = new Client({
        brokerURL: `${baseURL}/ws`,
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        beforeConnect: () => {
            return new Promise((resolve, _) => {
                resolve()
            })
        },

        onConnect: () => {
            func(stompClient);
        },
        debug: (str) => {
            // console.log(new Date(), str);
        }
    });

    stompClient.activate();
}

export function publish(destination, body) {
    if (stompClient) {
        stompClient.publish({destination: destination, body: JSON.stringify(body)});
    }
}