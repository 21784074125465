import React from "react";
import {Box, Paper, Typography} from "@mui/material";
import {styled} from "@mui/styles";

export const PaperItem = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

export const TopActionsPanel = ({children}) => {
    return <Box key={"top_actions_panel"}
                display={"flex"} flexDirection={"row"}
                width={"100%"}
                justifyContent={"flex-start"}>
        {children}
    </Box>
}

export const BottomActionsPanel = ({children}) => {
    return <Box key={"bottom_actions_panel"}
                display={"flex"} flexDirection={"row"}
                sx={{position: "sticky", bottom: 0, zIndex: 3}}
                width={"100%"}
                justifyContent={"flex-end"}
                gap={1} p={1}>
        {children}
    </Box>
}

export const BreakWordTypography = ({text, maxWidth}) => {
    return <Typography typography={'caption'} align={"center"}
                       sx={{
                           maxWidth: maxWidth ? maxWidth : '5rem',
                           overflow: "hidden",
                           textOverflow: "ellipsis",
                           display: "-webkit-box",
                           WebkitBoxOrient: "vertical",
                           wordBreak: 'break-word',
                       }}>{text}</Typography>
}

export const FlexMainPage = ({children, sx}) => {
    return <Box display={"flex"} flexDirection={"column"} flex={1} overflow={"auto"} justifyContent={"flex-start"} sx={{...sx}}>
        {/*<Box display={"flex"} flexDirection={"column"} flex={1} justifyContent={"flex-start"}>*/}
            {children}
        {/*</Box>*/}
    </Box>
}

export const FlexTablePanel = ({emptyMessage, children}) => {
    return <Box display={"flex"} flexDirection={"column"} p={1} flexGrow={1}>
        {
            children?.length > 0 && <Box display={"grid"}
                                         gridTemplateColumns={"repeat(auto-fill, minmax(300px, 1fr))"}
                                         gridAutoRows={"max-content"}
                                         gap={1}>
                {children}
            </Box>
        }
        {
            (!children || children?.length === 0) &&
            <Box display={"flex"} flexDirection={"column"} flex={1} flexGrow={1} textAlign={"center"}
                 justifyContent={"center"}>
                <Typography variant={"h5"}>{emptyMessage}</Typography>
            </Box>
        }
    </Box>
}
