import React from "react";
import {genConfig} from "react-nice-avatar";
import ReactNiceAvatar from "react-nice-avatar";
import {Avatar as MuiAvatar} from "@mui/material";

export const getAvatar = () => {
    let avatar = localStorage.getItem("avatar");
    if (!avatar) {
        avatar = JSON.stringify(genConfig())
        localStorage.setItem("avatar", avatar)
    }
    return avatar
}

export const UserAvatar = ({user, size}) => {
    size = size ? size : "48px"
    if (user.accountType === "ANONYMOUS") {
        return <ReactNiceAvatar style={{width: size, height: size,}}{...JSON.parse(user.avatar)} />
    }
    return <MuiAvatar sx={{width: size, height: size}} src={user?.pictureUrl}/>
}
