import React from "react";
import {Box, Typography} from "@mui/material";
import {useDefaultContext} from "../../context/WSContext";
import {PolaroidCard} from "./PolaroidCard";
import {BottomActionsPanel} from "../../components/base";
import ReturnToHomeBtn from "../../components/ReturnToHomeBtn";

export const FaqPage = () => {
    const {t} = useDefaultContext();

    return <Box display={"flex"} flexDirection={"column"} flexGrow={"1"} p={2} gap={1}>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant={"h4"} left>{t("Что такое MaybeWine?")}🍷</Typography>
            <Typography textAlign={"justify"}
                        variant={"h6"}>{t("MaybeWine - это приложение для проведения слепых винных дегустаций. Его придумали и разработали мы - Татьяна Хомякова и Дмитрий Неволин. Будем очень рады, если наш проект окажется полезным для ваших дружеских посиделок c вином.")}
                <br/>{t("Добавляйте вина, отправляйте ответы и поздравляйте победителей!")}
                <br/>{t("Если у вас есть вопросы, отзывы или предложения, пишите на почту:")} <a
                    href="mailto:help.maybe.wine@gmail.com">help.maybe.wine@gmail.com</a></Typography>
        </Box>
        <Box display={"flex"} flexWrap={"wrap"} flexDirection={"row"} gap={2} flexGrow={1}
             alignItems={"center"} justifyContent={"center"} alignContent={"center"}>
            <PolaroidCard image={"/tatiana.jpg"} tape={"tape-section"}/>
            <PolaroidCard image={"/dmitrii.jpg"} tape={"top-tape"}/>
        </Box>
        <BottomActionsPanel>
            <ReturnToHomeBtn/>
        </BottomActionsPanel>
    </Box>
}