import React from "react";
import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/material/styles";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography, useMediaQuery
} from "@mui/material";
import {useDefaultContext, useWSContext} from "../context/WSContext";
import {UserAvatar} from "../help/avatar";
import {WineDescription, WineDescriptionCard} from "./wineViewUtils";
import {findAnswer, findAnswers} from "../help/answerUtils";
import {AnswerCard} from "./answer/AnswerCard";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '80vh',
    },
    row: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover
        }
    },
    head: {
        position: "sticky",
        zIndex: 2,
        top: 0,
        backgroundColor: theme.palette.background.default,
    },
    angle_sticky_cell: {
        position: "sticky",
        zIndex: 2,
        left: 0,
        backgroundColor: theme.palette.background.default,
    },
    sticky_cell: {
        position: "sticky",
        zIndex: 1,
        left: 0,
        backgroundColor: theme.palette.background.default,
    },
    footer: {
        bottom: 0,
        zIndex: 2,
        position: "sticky",
        backgroundColor: theme.palette.background.default,
    }
}));


export const ResultTable = () => {
    const {t} = useDefaultContext();
    const {wines, users, results} = useWSContext();
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return <TableContainer className={classes.root}>
        <Table aria-label="sticky table">
            <TableHead className={classes.head}>
                <TableRow className={classes.row}>
                    <TableCell className={classes.angle_sticky_cell} key={"sticky_cell_1"}>
                        <Typography variant={"h6"}>{t("Вино")}</Typography>
                    </TableCell>
                    {
                        users.map((user) => {
                            return <TableCell className={classes.sticky_cell} key={user?.uuid}>
                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                    <UserAvatar user={user} size={64}/>
                                </Box>
                            </TableCell>
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    wines.map((wine) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={`row_${wine?.uuid}`}
                                      className={classes.row}>
                                <TableCell key={wine?.uuid} className={classes.sticky_cell}>
                                    {
                                        !fullScreen && <WineDescriptionCard wine={wine}/>
                                    }
                                    {
                                        fullScreen && <WineDescription wine={wine}/>
                                    }
                                </TableCell>
                                {
                                    users.map(user => {
                                        let answer = findAnswer(results, user, wine)
                                        return (
                                            <TableCell align="center" key={`${wine?.uuid}_${user?.uuid}`}>
                                                <AnswerCard answer={answer} user={user} wine={wine}/>
                                            </TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        );
                    })}
            </TableBody>
            <TableFooter className={classes.footer}>
                <TableRow>
                    <TableCell className={classes.sticky_cell}>
                        <Typography variant={"h6"}>{t("Итого")}</Typography>
                    </TableCell>

                    {
                        users.map((user, i) => {
                            const points = findAnswers(results, user).map(a => a.points).reduce((a, b) => a + b, 0);
                            return <TableCell align="center" key={`points_${i}`}>
                                <Typography variant={"h6"}>{points}</Typography></TableCell>
                        })
                    }

                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
}