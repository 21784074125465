import React, {createContext, useEffect, useState} from 'react';
import {connect} from "../requests/ws";
import {
    loadCountriesDictionary,
    loadGrapesDictionary,
    loadRegionsDictionary, joinDegustation, loadGoogleUser
} from "../requests/api";
import {findCurrentAnswer, findDefaultNextWine} from "../help/answerUtils";

const defaultContextValue = {
    currentUser: {}, setCurrentUser: (currentUser) => {
    },
    isLogin: false, setIsLogin: (isLogin) => {
    },
    grapesDictionary: [], countriesDictionary: [], regionsDictionary: [],
    t: () => {
    }
}
export const DefaultContext = createContext(defaultContextValue);
export const useDefaultContext = () => React.useContext(DefaultContext);

export const DefaultProvider = ({children, value}) => {
    const [grapesDictionary, setGrapesDictionary] = useState([]);
    const [countriesDictionary, setCountriesDictionary] = useState([]);
    const [regionsDictionary, setRegionsDictionary] = useState([]);
    const {currentUser, setCurrentUser, isLogin, setIsLogin} = value;

    useEffect(() => {
        loadGoogleUser()
            .then((result) => {
                setIsLogin(!!result)
                setCurrentUser(result);
            }).catch((e) => {
            console.log(e)
            setIsLogin(false);
            setCurrentUser({});
        })
        loadGrapesDictionary((response) => setGrapesDictionary(response))
        loadCountriesDictionary((response) => setCountriesDictionary(response))
        loadRegionsDictionary((response) => setRegionsDictionary(response))

    }, [])
    return (<DefaultContext.Provider value={{
        ...value,
        currentUser, setCurrentUser, isLogin, setIsLogin,
        grapesDictionary,
        countriesDictionary,
        regionsDictionary,
    }}>
        {children}
    </DefaultContext.Provider>);
}


const wsContextValue = {
    uuid: undefined, state: undefined, owner: undefined,
    currentWine: {}, currentAnswer: {},
    results: {}, wines: [], answers: [], users: [],
    defaultNextWine: null, setDefaultNextWine: (wine) => {
    }
}
export const WSContext = createContext(wsContextValue);
export const useWSContext = () => React.useContext(WSContext);


export const WSProvider = ({children, uuid}) => {
    const {
        currentUser
    } = useDefaultContext();

    const [state, setState] = useState({})

    useEffect(() => {

        const updateField = (fields, body) => {
            fields.forEach(field => {
                switch (field) {
                    case "wines":
                        setState((prevState => {
                            // console.log(prevState)
                            return {
                                ...prevState,
                                wines: body.wines,
                                defaultNextWine: findDefaultNextWine(body)
                            }
                        }));
                        break;
                    case "users":
                        setState((prevState => {
                            return {...prevState, users: body.users}
                        }));

                        break;
                    case "results":
                        const answer = findCurrentAnswer(body, currentUser)
                        setState((prevState => {
                            return {
                                ...prevState,
                                results: body.results,
                                currentAnswer: prevState.currentAnswer?.uuid !== answer?.uuid ? answer : prevState.currentAnswer
                            }
                        }));
                        break;
                    default:
                        // console.log(body)
                }
            })
        }

        function subscribeUserTopics(response) {
            const result = JSON.parse(response.body);
            switch (result.type) {
                case 'SEND_FULL':
                    setState(prevState => {
                        return {
                            ...prevState,
                            ...result.body,
                            currentAnswer: findCurrentAnswer(result.body, currentUser),
                            defaultNextWine: findDefaultNextWine(result.body)
                        }
                    })

                    break;
                case 'SEND_DIFF':
                    updateField(result.fields, result?.body);
                    break;
                default:
            }
        }

        if (uuid !== undefined && currentUser.uuid !== undefined) {
            joinDegustation(uuid).then(response => {
                setState(prevState => {
                    return {
                        ...prevState,
                        ...response,
                        currentAnswer: findCurrentAnswer(response, currentUser),
                        defaultNextWine: response?.wines?.filter(wine => wine.state === "CLOSED")[0]
                    }
                })
                connect(uuid, (stompClient) => {
                    if (stompClient !== null) {
                        // console.log(`connect /user/${currentUser.uuid}/degustation/${uuid}`)
                        stompClient.subscribe(`/user/${currentUser.uuid}/degustation/${uuid}`, subscribeUserTopics);
                    }
                });
            })
        }
    }, [currentUser, uuid])

    return (<WSContext.Provider value={{...state}}>
        {children}
    </WSContext.Provider>);
}

// <Compose items={[[Context, {degustation, currentUser}]]}>{children}</>
export default function Compose({items, children}) {
    return items.reduceRight((acc, [Context, props]) => React.createElement(Context.Provider, props, acc), children);
}
