import {defaultAnswer} from "./constants";

export const findCurrentAnswer = (degustation, currentUser) => {
    if (!currentUser.uuid || !degustation?.results) return defaultAnswer;
    const result = degustation?.results[currentUser?.uuid]?.find(answer => answer.wine?.uuid === degustation.currentWine?.uuid)
    return result !== undefined ? result : defaultAnswer
}

export const findAnswer = (results, currentUser, wine) => {
    if (!currentUser?.uuid || !wine?.uuid) return defaultAnswer;
    const result = results[currentUser?.uuid]?.find(answer => answer.wine.uuid === wine?.uuid)
    return result !== undefined ? result : defaultAnswer
}

export const findAnswers = (results, user) => {
    if (!user?.uuid || !results) return [];
    const result = results[user.uuid]
    return result !== undefined ? result : []
}

export const findDefaultNextWine = (degustation) => {
    if (degustation?.state === 'COMPLETED') return null;
    const wines = degustation?.wines?.filter(wine => wine.state === "CLOSED");
    return wines?.length > 0 ? wines[0] : null
}

export const mapPoints = (points, t) => {
    if (!points || points === 0) return `0 ${t("баллов")}`;
    if (points === 1) return `${points} ${t("балл")}`;
    if (points >= 2 || points <=4) return `${points} ${t("балла")}`;
    return `${points} ${t("баллов")}`;
}