import React, {Fragment, useEffect, useState} from "react";
import {Box, Chip, Fab, IconButton, Stack, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate, useParams} from "react-router-dom";
import {useDefaultContext} from "../../context/WSContext";
import {createDegustation, findDegustations} from "../../requests/api";
import {
    BottomActionsPanel, FlexTablePanel,
    PaperItem,
} from "../../components/base";
import {UserAvatar} from "../../help/avatar";
import LoginIcon from '@mui/icons-material/Login';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export const DashboardPage = () => {

    const {isLogin, t} = useDefaultContext();
    const [degustations, setDegustations] = useState([])

    const navigate = useNavigate();
    const params = useParams();
    const degustationUUID = params.degustationUUID;

    useEffect(() => {
        if (!isLogin) {
            navigate("/login", {
                state: {degustationUUID: degustationUUID}
            })
        } else {
            findDegustations().then(response => {
                setDegustations(response)
            })
        }
    }, [isLogin, navigate, degustationUUID]);

    const createDegustationBtn = () => {
        createDegustation()
            .then(response => {
                navigate(`/${response.uuid}`)
            })
    }

    const getColor = (state) => {
        if (state === "CREATED") return "warning";
        if (state === "STARTED") return "success";
        if (state === "COMPLETED") return "secondary";
        return "default"
    }

    return <Fragment>
        <FlexTablePanel emptyMessage={`${t("Здесь будут ваши дегустации")} 🍷`}>
            {
                degustations.map(degustation => {
                    return <PaperItem key={degustation.uuid} elevation={6}>
                        <Box display={"flex"} flexDirection={"row"} p={2} gap={2} alignItems={"center"}>
                            <Box sx={{all: "initial"}}>
                                <UserAvatar user={degustation.owner} size={64}/>
                            </Box>
                            <Stack direction={"column"} gap={1} width={"100%"}>
                                <Typography>{`${t("Дегустация пользователя")}`}</Typography>
                                <Typography>{degustation.owner.username}</Typography>
                                <Chip key={`createDate_${degustation?.uuid}`} icon={<CalendarMonthIcon/>}
                                      label={new Date(degustation.createDate).toLocaleDateString()}/>
                                <Chip key={`state_${degustation?.uuid}`}
                                      color={getColor(degustation.state)}
                                      label={t(degustation.state)}/>
                            </Stack>
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                                <IconButton size="small" aria-label="open degustation"
                                            onClick={() => navigate(`/${degustation.uuid}`)}><LoginIcon/></IconButton>
                            </Box>
                        </Box>
                    </PaperItem>
                })
            }
        </FlexTablePanel>
        <BottomActionsPanel>
            <Fab size="large" color="primary" aria-label="add" onClick={createDegustationBtn}>
                <AddIcon/>
            </Fab>
        </BottomActionsPanel>
    </Fragment>
}