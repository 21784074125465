import React, {Fragment} from "react";
import {Box, Fab, Tooltip, Typography} from "@mui/material";
import {AnswerForm} from "../components/answer/AnswerForm";
import {useDefaultContext, useWSContext} from "../context/WSContext";
import CopyLinkBtn from "../components/CopyLinkBtn";
import {openWine} from "../requests/api";
import {BottomActionsPanel, TopActionsPanel} from "../components/base";
import {UsersPanel} from "../components/user/UsersPanel";
import {WineCard} from "../components/wine/WineCard";
import {PlayArrowSharp as PlayArrowSharpIcon} from '@mui/icons-material';


export const AddAnswerPanel = () => {
    const {
        currentUser, t,
    } = useDefaultContext();
    const {currentWine, uuid, owner, answers} = useWSContext();

    const isOwnerOfDegustation = owner?.uuid === currentUser.uuid;
    const isOwnerOfWine = currentWine?.owner?.uuid === currentUser.uuid;
    const canOpenWine = isOwnerOfDegustation && answers?.length !== 0;

    return <Fragment>
        <TopActionsPanel>
            <UsersPanel/>
        </TopActionsPanel>
        <Box display={"flex"} flexDirection={"column"} flex={1} flexGrow={1} textAlign={"center"}
             justifyContent={"center"}>
            { !isOwnerOfWine && <AnswerForm/> }
            { isOwnerOfWine && <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Typography>{t("Пользователи отгадывают ваше вино!")}</Typography>
                <br/>
                <WineCard key={currentWine.uuid} wine={currentWine}/></Box>}
        </Box>
        <BottomActionsPanel>
            <CopyLinkBtn uuid={uuid} t={t}/>
            <Tooltip title={t("Открыть вино может только владелец дегустации")}>
                <span>
                    <Fab size="large" color="success" aria-label="open the wine"
                         disabled={!canOpenWine} onClick={() => openWine(uuid, currentWine?.uuid)}>
                             <PlayArrowSharpIcon/>
                    </Fab>
                </span>
            </Tooltip>
        </BottomActionsPanel>
    </Fragment>
}