import React, {Fragment} from 'react';
import {Box, Chip, Divider, Stack, Typography} from "@mui/material";
import {getLocaleForFields, getOptionLabel} from "../help/lang";
import {getArea} from "../help/fieldsMapping";
import {UserAvatar} from "../help/avatar";
import {useDefaultContext} from "../context/WSContext";
import {
    Percent as PercentIcon,
    WineBar as WineBarIcon,
    LocationOn as LocationOnIcon,
    Lock as LockIcon,
    CalendarMonth as CalendarMonthIcon
} from '@mui/icons-material';

const locale = getLocaleForFields();

const LockedDescription = () => <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"}
                                         gap={1} flexGrow={1}
                                         flexWrap={"wrap"} width={"100%"} height={"100%"} minHeight={"8rem"}>
    <Box display={"flex"} flexGrow={1}
         justifyContent={"center"} alignItems={"center"}
         width={"100%"} height={"100%"}
         borderRadius={"10px"}
         border={"1px solid rgba( 255, 255, 255, 0.18 )"}
        // boxShadow={"0 8px 32px 0 rgba( 31, 38, 135, 0.37 )"}
         sx={{
             backgroundColor: "rgba(126,110,110,0.5)",
             backdropFilter: "blur( 10px )",
             "-webkit-backdrop-filter": "blur( 10px )"
         }}
    >
        <LockIcon/></Box>
</Stack>

const Chips = ({wine, t}) => {
    return <Stack direction={"row"} flexWrap={"wrap"} gap={1}
                  justifyContent={"center"}>
        {
            wine?.grapes.map(grape => {
                const name = getOptionLabel(locale, grape, t);
                return <Chip key={`grapes_${wine?.uuid}_${grape?.code}`} icon={<WineBarIcon/>} label={name}/>
            })
        }
        <Chip key={`location_${wine?.uuid}`} icon={<LocationOnIcon/>} label={getArea(wine, t, locale)}/>
        <Chip key={`vintage_${wine?.uuid}`} icon={<CalendarMonthIcon/>}
              label={wine?.vintage}/>
        <Chip key={`alcohol_${wine?.uuid}`} icon={<PercentIcon/>}
              label={wine?.alcohol}/>
    </Stack>
}

const FullDescription = ({wine}) => {
    const {t} = useDefaultContext();
    return <Fragment>
        <Box component="img" src={"/wineglass_12991689.png"}
             sx={{
                 width: "128px",
                 height: "128px",
             }}/>
        <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} gap={1} flexGrow={1}
               flexWrap={"wrap"} width={"100%"} height={"100%"}>
            <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
                {
                    wine?.grapes.map(grape => {
                        const name = getOptionLabel(locale, grape, t);
                        return <Chip key={`grapes_${wine?.uuid}_${grape?.code}`} icon={<WineBarIcon/>} label={name}/>
                    })
                }
            </Stack>
            <Chip key={`location_${wine?.uuid}`} icon={<LocationOnIcon/>} label={getArea(wine, t, locale)}/>
            <Stack direction={"row"} gap={1}>
                <Chip key={`vintage_${wine?.uuid}`} icon={<CalendarMonthIcon/>}
                      label={wine?.vintage}/>
                <Chip key={`alcohol_${wine?.uuid}`} icon={<PercentIcon/>}
                      label={wine?.alcohol}/>
            </Stack>
        </Stack>
    </Fragment>
}

export const WineDescription = ({wine}) => {
    const {currentUser} = useDefaultContext();
    const isOwner = currentUser?.uuid === wine?.owner?.uuid;
    const isOpened = wine?.state === 'OPENED'
    const canShow = isOpened || isOwner

    return canShow ? <FullDescription wine={wine}/> : <LockedDescription wine={wine}/>
}

export const WineDescriptionUserBlock = ({wine, children}) => {
    return <Stack direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-around"}>
        <Box sx={{all: "initial"}}><UserAvatar user={wine.owner}/></Box>
        <Stack direction={"column"} alignItems={"center"}>
            <Typography variant={"subtitle1"} flexGrow={1}>{wine.name}</Typography>
            <Typography variant={"subtitle2"} flexGrow={1}>{wine.owner.username}</Typography>
        </Stack>
        {
            children
        }
    </Stack>
}

export const WineDescriptionCard = ({wine, children}) => {
    return <Stack direction={"column"} flexGrow={1} gap={0.5}>
        {
            <Fragment>
                <WineDescriptionUserBlock wine={wine}>{children}</WineDescriptionUserBlock>
                <Divider sx={{mt: 1}}/>
            </Fragment>
        }
        <Stack direction={"row"} flexGrow={1} alignItems={"center"}>
            <WineDescription wine={wine}/>
        </Stack>
    </Stack>
}


export const WineDescriptionLine = ({wine, text}) => {
    const {t, currentUser} = useDefaultContext();
    const isOwner = currentUser?.uuid === wine?.owner?.uuid;
    const isOpened = wine?.state === 'OPENED'
    const canShow = isOpened || isOwner

    return <Stack direction={"column"} p={1} gap={2}>
        <WineDescriptionUserBlock wine={wine}>
            {<Chip key={`order_${wine.uuid}`} label={text}/>}
            {
                !canShow && <LockIcon/>
            }
        </WineDescriptionUserBlock>

        {canShow && <Chips wine={wine} t={t}/>}
    </Stack>
}
